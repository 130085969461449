<template>
  <div class="nav-container">
    <div>
      <input id="butadd" type="button" name="butadd" @click="onAddRow()" hidden>
      <label for="butadd" class="pointer menu-button">
        <img src='/plus-solid.svg' alt='bild hinzufügen' class='icon-green-small' style="vertical-align:middle"/>&nbsp;
        Neues Werkstück
      </label>
    </div>
    <div>
      <a class="menu-button" target="_blank" href="https://drive.google.com/drive/u/1/folders/1VK-wD1V03j4mjDE3yZUdW-e2LyaDmNld">
        <img src='/google-drive.svg' alt='image icon' class='icon-green-small'
             style="vertical-align:middle"/>&nbsp; Bilder GDrive
      </a>
    </div>
    <div>
      <a class="menu-button" target="_blank" href="https://werner-fenz.at">
        <img src='/house-solid.svg' alt='homepage icon' class='icon-green-small'
             style="vertical-align:middle"/>&nbsp; Web
      </a>
    </div>
    <div>
      <input id="butrefresh" type="button" name="butrefresh" @click="onRefresh()" hidden>
      <label for="butrefresh" class="pointer menu-button">
        <img src='/arrows-rotate-solid.svg' alt='refresh icon' class='icon-green-small'
             style="vertical-align:middle"/>&nbsp; Refresh
      </label>
    </div>

    <div style="float:right;">
      <input id="butrefreshhard" type="button" name="butrefreshhard" @click="onHardRefresh()" hidden>
      <label for="butrefreshhard" class="pointer menu-button">
        <img src='/arrows-rotate-solid.svg' alt='hard refresh icon' class='icon-orange-small'
             style="vertical-align:middle"/>&nbsp; Hard Refresh
      </label>
    </div>
    <br style="clear:both"/>
  </div>
</template>
<script>

import {notify} from "@kyvg/vue3-notification";

export default {
  name: "MainMenu",
  props: {
    controller: String,
    gridApi: Object,
  },
  methods: {
    async onAddRow() {
      const data = JSON.stringify({
        "id": null,
        "order_nr": "NEW" + Math.floor(Math.random() * 1001),
        "name": "",
        "description": "",
        "is_in_stock": false,
        "stock_quantity": 0,
        "is_visible": false,
        "price": 0.0,
        "category": "Unkategoriesiert",
        "wood": "",
        "heritage": "",
        "diameter": "",
        "height": "",
        "thickness": "",
        "wood_turning_type": "",
        "surface": "",
        "order": "?",
        "images": [],
      })
      await fetch(this.controller + 'products/', {
        method: 'POST',
        body: data,
        credentials: "include",
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      }).then((result) => result.json())
          .then((row) => {
            this.gridApi.applyTransaction({add: [row]});
          }).catch(() => {
                notify({
                  title: 'Werkstück nicht hinzugefügt',
                  text: "Beim Erstellen des Werkstücks ist ein Fehler aufgetreten!",
                  type: 'error',
                  duration: "5000"
                });
              }
          );
    },

    async onRefresh() {
      location.reload();
    },

    async onHardRefresh() {
      fetch(this.controller + "products/fields/?hard_refresh=true", {credentials: "include"});
      fetch(this.controller + "products/?hard_refresh=true", {credentials: "include"});

      setTimeout(function () {
        location.reload();
      }, 50);
    }
  }
}
</script>


<style scoped>
.menu-button {
  display: inline-block;
  padding: 10px 10px;
  margin-bottom: 15px;
  margin-right: 15px;
  font-size: 15px;
  height: 23px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #181d1f;
  border: 1px solid #555555;
  border-radius: 3px;
  box-shadow: 3px 3px black;
  float: left;
}

.menu-button:hover {
  border: 1px solid #181d1f;
  background-color: #303b3f;
}

.menu-button:active {
  border: 1px solid #181d1f;
  box-shadow: 1px 1px #4e5a5e;
}

.icon-green-small {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(80%) contrast(119%);
  height: 20px;
  width: 20px;
}

.icon-orange-small {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(0deg) brightness(118%) contrast(119%);
  height: 20px;
  width: 20px;
}

</style>