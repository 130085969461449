<template>
  <div class="nav-container"
       :style="{ width: editorWidth + 'px', height:editorHeight+'px', lineHeight:editorHeight+'px', textAlign:'center'}">
    <input id='button' type='button' name='button' @click="onDeleteProduct" hidden>
    <label for='button' class='row-delete pointer'><img src='/trash-solid.svg' alt='image icon '
                                                        class='icon-red' :style="{verticalAlign:'middle'}"/></label>
  </div>
</template>


<script>
import {nextTick} from 'vue';
import ConfirmDeleteDialog from './ConfirmDeleteDialog.vue'
import {createConfirmDialog} from 'vuejs-confirm-dialog'
import {notify} from "@kyvg/vue3-notification";


export default {
  name: 'ButtonCellEditor',
  components: {},
  data() {
    return {
      gridApi: '',
      product: '',
      editorWidth: 100,
      editorHeight: 100,
      cancelBeforeStart:true
    }
  },
  methods: {
    async onDeleteProduct() {
      const tmp_order_nr = this.data.order_nr
      const {
        reveal,
        onConfirm
      } = createConfirmDialog(ConfirmDeleteDialog, {
        question: 'wirklich endgültig löschen?',
        orderNr: this.data.order_nr,
        img:this.data?.images?.[0]?.src ? this.data?.images?.[0]?.src:'/image-solid.svg',
        color:'#ffa6a3'
      });
      onConfirm(() => {
        this.gridApi.deselectAll();
        this.gridApi.applyTransaction({remove: [this.data]})
        fetch(this.params.controller + 'products/' + this.data.id, {
          method: 'DELETE',
          credentials: "include"
        }).then((response) => response.json())
            .then(() => {
              notify({
                title: 'Werkstück ' + tmp_order_nr,
                text: "Erfolgreich gelöscht!",
                type: 'success'
              });
            }).catch(() => {
              notify({
                title: 'Werkstück ' + tmp_order_nr,
                text: "Werkstück konnte nicht gelöscht werden! Ein Refresh könnte nötig sein!!",
                duration:"5000",
                type: 'error'
              });
            }
        );
      });
      await reveal();
    },
    getValue() {
      return this.selected_option.value;
    },
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },
    isCancelAfterEnd() {
      return true;
    },
  },
  created() {
    this.data = this.params.data;
    this.gridApi = this.params.api;
    this.editorWidth = this.params.column.actualWidth;
    this.editorHeight = this.params.node.rowHeight;
    this.cancelBeforeStart = this.params.data.id === -1;
  },
  mounted() {
    nextTick(() => {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    });
  }
}
</script>

<style scoped>

.image-select {
  height: inherit;
  width: inherit;
  padding: inherit;
  margin: inherit;
  border: solid 0 !important;
  border-radius: 0 !important;
  background-color: white !important;
  vertical-align: center;
}

</style>