<template>
  <div class="select-container">
    <v-select
        v-model="value"
        :options="options"
        :ref="'input1'"
        :multiple=false
        :disabled=false
        :searchable=true
        :filterable=true
        :taggable=true
        :clearable="false"
        :push-tags="true"
        :appendToBody="true"
        :autoscroll="true"
        :style="{ width: editorWidth + 'px', margin:0, padding:0, border:'none', height: editorHeight + 'px'}"
        @click="scrollDown"
        class="my-select"
        label="title"
        auto
    ></v-select>
  </div>
</template>

<script>
import {nextTick} from 'vue';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

function html2text(value){
  const eDiv = document.createElement('div');
  eDiv.innerHTML = value;
  return eDiv.textContent;
}


export default {
  name: 'DropDownCellEditor',
  components: {"v-select": vSelect},
  data() {
    return {
      value: '',
      options: '',
      editorWidth: 100,
      editorHeight: 100,
      optionsExtendable: true,
      cancelBeforeStart: true
    }
  },
  methods: {
    getValue() {
      return html2text(this.value);
    },
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },
    isCancelAfterEnd() {
      return false;
    },
    scrollDown(){
      const element = document.getElementsByClassName("vs__dropdown-menu")[0];
      element.scrollIntoView(false);
    }
  },
  created() {
    this.value = html2text(this.params.value);
    this.editorWidth = this.params.column.actualWidth;
    this.editorHeight = this.params.node.rowHeight;
    if (this.params.colDef.options) {
      this.options = this.params.colDef.options.map(html2text);
    } else {
      this.options = [];
    }

    this.cancelBeforeStart = this.params.data.id === -1;
  },
  mounted() {
    nextTick(() => {
    });
  }
}
</script>

<style scoped>
.my-select{
  color:white;
}

.select-container {
  position: fixed;
  overflow:visible;
}
.v-select__selection--comma {
  overflow: unset;

}

.v-list-item__title, .v-list-item__subtitle {
  overflow: unset;
}


</style>