<template>
  <div class="textcelleditor" :style="{ width: editorWidth + 'px', height:'400px', margin:0, padding:0, border:'none'}">
    <QuillEditor class="editor-bg" v-model:content="value" contentType="html" @keydown="onKeyPressed($event)" :toolbar="[
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['link'],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            ['clean']
          ]"/>
  </div>
</template>

<script>
import {nextTick} from 'vue';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
  components: {QuillEditor},
  name: 'TextCellEditor',
  data() {
    return {
      value: '',
      cancelBeforeStart: true,
      editorWidth: 100,
    }
  },
  methods: {
    getValue() {
      return this.value;
    },
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },
    isCancelAfterEnd() {
      return false;
    },
    onKeyPressed(e) {
      if (e.key === 'Enter') {
        e.cancel = true;  // prevent the default behavior of enter key with Grid
        e.stopPropagation();
      }
    }
  },
  created() {
    this.value = this.params.value;
    this.editorWidth = this.params.column.actualWidth;
    this.cancelBeforeStart = this.params.data.id === -1;
  },
  mounted() {
    nextTick(() => {});
  }
}
</script>

<style>
.textcelleditor {
  height: inherit;
  padding: inherit;
  margin: inherit;
  border: 0.5px solid gray !important;
  border-radius: 0 !important;
  background-color: #2f2f2f !important;
}

.editor-bg{
  border: none !important;
  height: 300px;
  background-color: #2f2f2f;
}
</style>