<template>
  <div class="centered">
    <div class="image-cell-editor" :style="{ height: editorHeight + 'px', width: editorWidth + 'px'}">
      <draggable
          v-model="values"
          group="people"
          @start="drag=true"
          @end="drag=false"
          item-key="id">
        <template #item="{element}">
          <div class="dragable-a parent" id="img-{{element.id}}">
            <img :src="element.src" alt="{{element.name}}" class="dragable-image image1"/>
            <div @click="onDelete(element.id)" class="image2"><img src='/x-solid.svg' alt='bild entfernen'
                                                                   class='icon-red-small pointer'/></div>
          </div>
        </template>
      </draggable>

      <div :style="{float:'left'}">
        <input id="file" type="file" name="file" @change="onAddImage()" multiple hidden
               accept="image/png,image/PNG,image/jpg,image/JPG,image/jpeg,image/JPEG">
        <label for="file" class="image-add"><img src='/plus-solid.svg' alt='bild hinzufügen'
                                                 class='icon-green-big pointer'/> </label>
      </div>


    </div>
  </div>
</template>

<script>
import {nextTick} from 'vue';
import draggable from 'vuedraggable'
import {createConfirmDialog} from "vuejs-confirm-dialog";
import ConfirmDeleteDialog from "@/components/ConfirmDeleteDialog";
import {notify} from "@kyvg/vue3-notification";


async function getImageToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });
}

export default {
  name: 'ImageCellEditor',
  components: {
    draggable
  },
  data() {
    return {
      gridApi: '',
      productId: -1,
      orderNr: -1,
      values: [],
      valuesOld: [],
      editorWidth: 100,
      editorHeight: 100,
      cancelBeforeStart: true,
      drag: false,
    }
  },
  methods: {
    async onDelete(imgId) {
      let imgObj = this.values.filter(e => e.id === imgId)?.[0]
      const {
        reveal,
        onConfirm
      } = createConfirmDialog(ConfirmDeleteDialog, {
        question: 'Bild löschen?',
        orderNr: this.orderNr,
        img: imgObj?.src,
        color: 'white'
      });
      onConfirm(() => {
        this.values = this.values.filter(e => e.id !== imgId)
        this.editorWidth = 100 * (this.values.length + 1);
        fetch(this.params.controller + 'products/' + this.productId + '/images/' + imgId, {
          method: 'DELETE',
          credentials: "include"
        })
            .then((response) => response.json())
            .then(() => {
              notify({
                title: 'Werkstück: ' + this.orderNr,
                text: "Bild gelöscht!",
                type: 'success'
              });
            }).catch(() => {
              notify({
                title: 'Werkstück: ' + this.orderNr,
                text: "Bild konnte nicht gelöscht werden - Bitte versuche es erneut!",
                duration:"5000",
                type: 'error'
              });
            }
        )
      });
      await reveal();
    },
    async onAddImage() {
      let fileInput = document.getElementById("file")
      try {
        this.gridApi.stopEditing();
      } catch (e) {
        console.log(e);
      }
      let imageCollection = [];
      for (let i = 0; i < fileInput.files.length; i++) {
        const uploadedImageBase64 = await getImageToBase64(fileInput.files[i])
        const data = {
          "product_id": this.productId,
          "file_name": fileInput.files[i].name,
          "img_base64": uploadedImageBase64,
          "processed": false
        }
        imageCollection.push(data);
      }
      await fetch(this.params.controller + 'products/' + this.productId + '/images', {
        method: 'POST',
        credentials: "include",
        body: JSON.stringify(imageCollection),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      }).then( () => {
        notify({
          title: 'Werkstück: ' + this.orderNr,
          text: "Bild(er) erfolgreich zur weiteren Verarbeitung hochgeladen!",
          type: 'success'
        });
      }
    ).catch(() => {
            notify({
              title: 'Werkstück: ' + this.orderNr,
              text: "Bild(er) konnten nicht hochgeladen werden - Bitte versuche es erneut!",
              duration:"5000",
              type: 'error'
            });
          }
      )
    },
    getValue() {
      return this.values;
    },
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },
    isCancelAfterEnd() {
      const equals = (a, b) =>
          a.length === b.length &&
          a.every((v, i) => v === b[i]);
      return equals(this.values, this.valuesOld);
    },
  },
  created() {
    this.gridApi = this.params.api;
    this.values = this.params.value;
    this.valuesOld = this.params.value;
    this.productId = parseInt(this.params.node.data['id']);
    this.orderNr = this.params.node.data['order_nr'];
    this.editorWidth = 100 * (this.values.length + 1);
    this.editorHeight = this.params.node.rowHeight;
    this.cancelBeforeStart = this.params.data.id === -1;
  },
  mounted() {
    nextTick(() => {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    });
  },
}
</script>

<style>
.image-cell-editor {
  max-width: 600px;
  border-radius: 0 !important;
  background: transparent !important;
  vertical-align: top;
  margin: 0;
}

.dragable-image {
  float: left;
  height: 94px;
  width: 94px;
}

.dragable-a {
  float: left;
  height: 94px;
  width: 94px;
  margin: 2px;
  border: 1px solid gray !important;
}

.dragable-a:focus {
  margin: 2px;
  border: 2px solid goldenrod !important;
}

.add-icon {
  z-index: 99;
  height: 94px;
  width: 94px;
  display: flex;
  justify-content: center;
  vertical-align: center;
  text-align: center;
  margin: 14px 2px -14px;
}

.centered {
  vertical-align: center;
  text-align: center;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

.image1 {
  position: relative;
  top: 0;
  left: 0;
}

.image2 {
  position: absolute;
  top: 5px;
  right: 8px;
  border-radius: 1px;
  border: 1px black;
}

.pointer {
  cursor: pointer;
}
</style>