<template>
  <div style="height: 100%">
    <LoginButton :logged-in="loggedIn" :controller="controller" :disabled="disabled" :user="this.user"/>
    <div v-if="loggedIn">
      <ProductTable :controller="controller"/>
    </div>
    <DialogsWrapper/>
    <notifications position="bottom left" max="5" :ignore-duplicates="true" :pauseOnHover="true"/>
  </div>
</template>

<script>
import ProductTable from '@/views/ProductTable'
import LoginButton from '@/views/LoginButton'
import {onMounted} from "vue";

const controller = "https://admin-api.werner-fenz.at/";

export default {
  components: {
    ProductTable,
    LoginButton
  },
  data() {
    return {
      currentPath: window.location.hash,
      loggedIn: false,
      disabled: true,
      controller: controller,
      user: ""
    }
  },
  mounted() {
    window.addEventListener('hashchange', () => {
      this.currentPath = window.location.hash
    })
  },
  created() {
    onMounted(() => {
       fetch(this.controller + "user", {credentials: "include"})
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            }
            else{
              return null;
            }
          }).then(data => {
            if (data){
              this.loggedIn = true;
              this.user = data.message;
            } else {
              this.loggedIn = false;
              this.user = "";
            }
            this.disabled = false;
          })
    });
  }
}
</script>



<style>
body{
  background-color: #181d1f !important;
  --vs-controls-color: #444444;
  --vs-border-color: #444444;

  --vs-dropdown-bg: #282c34;
  --vs-dropdown-color: #999999;
  --vs-dropdown-option-color: #999999;

  --vs-selected-bg: #444444;
  --vs-selected-color: #eeeeee;

  --vs-search-input-color: #eeeeee;

  --vs-dropdown-option--active-bg: #444444;
  --vs-dropdown-option--active-color: #eeeeee;
}

.vue-notification {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}
.success {
  background: #68CD86;
}
.warn {
  background: #3989da;
}
.error {
  background: #E54D42;
}

.notification-content {
  font-size: 16px;
}
.notification-title {
  font-size: 14px;
}
</style>