<template>
  <input class="ag-cell cellinput" :ref="'input'" @keydown="onKeyDown($event)" v-model="value"/>
</template>

<script>
import {nextTick} from 'vue';

export default {
  name: 'CurrencyCellEditor',
  data() {
    return {
      value: '',
      cancelBeforeStart: true
    }
  },
  methods: {
    getValue() {
      return this.value;
    },
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },
    isCancelAfterEnd() {
      return !(/^\d*\.?\d*$/.test(this.value));
    },
    onKeyDown(event) {
      if (!this.isValid(event.key)) {
        if (event.preventDefault) event.preventDefault();
      }
    },
    isValid(char) {
      if (char === 'Backspace') return true;
      if (char === 'Enter') return true;
      if (char === 'Home') return true;
      if (char === 'End') return true;
      if (char === 'Delete') return true;
      if (char === 'Escape') return true;
      if (char === 'ArrowLeft') return true;
      if (char === 'ArrowRight') return true;
      if (char === 'ArrowUp') return true;
      if (char === 'ArrowDown') return true;
      if (char === '.' && this.value.indexOf('.') > -1) return false;
      let x = '1234567890.'.indexOf(char)
      return (x > -1);
    },
  },
  created() {
    this.value = this.params.value;
    this.cancelBeforeStart = this.params.charPress && ('1234567890.'.indexOf(this.params.charPress) < -1) || this.params.data.id === -1;
  },
  mounted() {
    nextTick(() => {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    });
  }
}
</script>

<style>
.cellinput {
  width: inherit;
  height: inherit;
  padding: inherit;
  margin: inherit;
  border: solid 0 !important;
  border-radius: 0 !important;
  vertical-align: top;
}

</style>