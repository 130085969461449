<template>
  <div class="nav-container" :style="{ width: editorWidth + 'px', margin:0, padding:0, border:'none', top:'auto'}">
    <v-select
        class="image-select"
        :multiple=false
        :disabled=false
        :searchable=false
        :filterable=false
        :taggable=false
        :clearable="false"
        :closeOnSelect="true"
        :options="options"
        label="value"
        v-model="selected_option"
        :menu-props="{top: true, offsetY: true}"
        auto
    >
      <template #selected-option="option">
        <div class="image-select">
          <img :src='option.src' :alt='option.alt' :class='option.clazz' />
        </div>
      </template>
      <template v-slot:option="option">
        <div>
          <img :src='option.src' :alt='option.alt' :class='option.clazz' />
        </div>
      </template>
    </v-select>
  </div>
</template>


<script>
import {nextTick} from 'vue';
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";

export default {
  name: 'BoolCellEditor',
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      label: '',
      cancelBeforeStart: true,
      editorWidth: 100,
      editorHeight: 100,
      selected_option: '',
      options: [
        {
          src: '/x-solid.svg',
          alt: 'x icon (=unset)',
          clazz: 'icon-red',
          value: false,
        },
        {
          src: '/check-solid.svg',
          alt: 'check icon (=set)',
          clazz: 'icon-green',
          value: true,
        }]
    }
  },
  methods: {
    getValue() {
      return this.selected_option.value;
    },
    isCancelBeforeStart() {
      return this.cancelBeforeStart;
    },
    isCancelAfterEnd() {
      return false;
    },
  },
  created() {
    this.label = this.params.column.colDef.headerName;
    this.selected_option = this.options.find((o) => o.value === this.params.value)
    this.editorWidth = this.params.column.actualWidth;
    this.editorHeight = this.params.node.rowHeight;
    this.cancelBeforeStart = this.params.data.id === -1;
  },
  mounted() {
    nextTick(() => {
      if (this.$refs.input) {
        this.$refs.input.focus();
      }
    });
  }
}
</script>

<style scoped>

.image-select {
  height: inherit;
  width: inherit;
  padding: inherit;
  margin: inherit;
  border: solid 0 !important;
  border-radius: 0 !important;
  background-color: white !important;
  vertical-align: center;
}

</style>