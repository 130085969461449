<template>
  <MainMenu :controller="controller" :grid-api="gridApi"/>
  <div :style="{height:this.tableHeight.value + 'px', width:'100%'}">
    <ag-grid-vue
        class="ag-theme-alpine-dark"
        style="height:inherit"
        :columnDefs="columnDefs.value"
        :rowData="rowData.value"
        :defaultColDef="defaultColDef"
        :rowHeight=100
        :getRowId="getRowId"
        :undoRedoCellEditing=true
        :undoRedoCellEditingLimit=200
        :enableCellChangeFlash=true
        rowSelection="single"
        animateRows="false"
        @cell-value-changed="onCellValueChanged"
        @grid-ready="onGridReady"
    >
    </ag-grid-vue>
  </div>

</template>
<script>
import {AgGridVue} from "ag-grid-vue3";
import {onMounted, reactive} from "vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import BoolCellEditor from '@/components/BoolCellEditor.vue';
import CurrencyCellEditor from '@/components/CurrencyCellEditor.vue';
import IntCellEditor from '@/components/IntCellEditor.vue';
import TextCellEditor from '@/components/TextCellEditor.vue';
import DropDownCellEditor from '@/components/DropDownCellEditor.vue';
import ImageCellEditor from "@/components/ImageCellEditor";
import ButtonCellEditor from "@/components/ButtonCellEditor";
import {notify} from "@kyvg/vue3-notification";
import MainMenu from "@/views/ProductTableMenu";

let sseClientImgJobs;

function get_cell_renderer(fieldDef) {
  let cell_renderer;

  switch (fieldDef.type) {
    case "link":
      cell_renderer = params => {
        const eDiv = document.createElement('div');
        if (params.data.id === -1) {
          return eDiv;
        }
        eDiv.innerHTML = "<a href='" + params.data.wp_permalink + "' target='_blank'>" + params.data.order_nr + "</a><br><span style='color:gray'>("+params.data.id+")<span>"
        return eDiv;
      }
      break;
    case "images":
      cell_renderer = params => {
        const eDiv = document.createElement('div')
        if (params.data.id === -1) {
          return eDiv;
        }
        eDiv.classList.add("flex-container")
        if (params.data.images !== null && params.data.images.length > 0) {
          eDiv.innerHTML = "<img  src='" + params.data.images[0].src + "' alt='" + params.data.images[0].name + "' style='float: left;' height='80px' width='80px'/>";
        } else {
          eDiv.innerHTML = "<img src='/image-solid.svg' alt='x icon (= un set)' height='80px' width='80px' class='icon-red'/>";
        }
        return eDiv;
      }
      break;
    case "bool":
      cell_renderer = params => {
        const eDiv = document.createElement('div');
        if (params.data.id === -1) {
          return eDiv;
        }
        let checkIcon = "<img src='/check-solid.svg' alt='check icon (=set)' class='icon-green'/>"
        let xIcon = "<img src='/x-solid.svg' alt='x icon (= un set)' class='icon-red'/>"
        let val = params.value ? checkIcon : xIcon
        eDiv.innerHTML = "<p>" + val + "</p>";
        return eDiv;
      }
      break;
    case "int":
      cell_renderer = params => {
        const eDiv = document.createElement('div')
        if (params.data.id === -1) {
          return eDiv;
        }
        eDiv.innerHTML = params.value;
        return eDiv;
      }
      break;
    case "currency":
      cell_renderer = params => {
        const eDiv = document.createElement('div')
        if (params.data.id === -1) {
          return eDiv;
        }
        eDiv.classList.add("cell-right")
        eDiv.innerHTML = "&euro; " + (Math.round(params.value * 100) / 100).toFixed(2);
        return eDiv;
      }
      break;
    case "text":
      cell_renderer = params => {
        const eDiv = document.createElement('div');
        if (params.data.id === -1) {
          eDiv.innerHTML = "<strong>Anzahl an Werkstücken: " + params.api.getDisplayedRowCount() + "</strong>";
          return eDiv;
        }
        eDiv.classList.add("cell-left")
        eDiv.classList.add('cell-wrap');
        eDiv.innerHTML = "<p>" + params.value + "</p>";
        return eDiv;
      }
      break;
    case "string":
      cell_renderer = params => {
        const eDiv = document.createElement('div');
        if (params.data.id === -1) {
          return eDiv;
        }
        eDiv.classList.add("cell-left")
        eDiv.innerHTML = params.value;
        return eDiv;
      }
      break;
    case "dropdown":
      cell_renderer = params => {
        const eDiv = document.createElement('div');
        if (params.data.id === -1) {
          return eDiv;
        }
        eDiv.classList.add("cell-left")
        eDiv.innerHTML = params.value;
        return eDiv;
      }
      break;
    case "select":
      cell_renderer = params => {
        const eDiv = document.createElement('div');
        if (params.data.id === -1) {
          return eDiv;
        }
        eDiv.classList.add("cell-left")
        eDiv.innerHTML = params.value;
        return eDiv;
      }
      break;
    case "button":
      cell_renderer = params => {
        const eDiv = document.createElement('div');
        if (params.data.id === -1) {
          return eDiv;
        }
        eDiv.innerHTML = "<input id='butdel' type='button' name='butdel' hidden>" +
            "<label for='butdel' class='row-delete'><img src='/trash-solid.svg' alt='image icon' " +
            "class='icon-gray'/></label> ";
        return eDiv;
      }
      break;
  }
  return cell_renderer;
}

function get_cell_editor(fieldDef) {
  let cell_editor;
  switch (fieldDef.type) {
    case "images":
      cell_editor = ImageCellEditor;
      break;
    case "bool":
      cell_editor = BoolCellEditor;
      break;
    case "int":
      cell_editor = IntCellEditor;
      break;
    case "currency":
      cell_editor = CurrencyCellEditor;
      break;
    case "text":
      cell_editor = TextCellEditor;
      break;
    case "string":
      cell_editor = 'agTextCellEditor';
      break;
    case "dropdown":
      cell_editor = DropDownCellEditor;
      break;
    case "button":
      cell_editor = ButtonCellEditor;
      break;
  }
  return cell_editor;
}

function get_columnDef(field, fieldDef, controller) {
  let colDef = {
    field: field,
    headerName: fieldDef.name,
    sortable: fieldDef.sortable,
    filter: fieldDef.filter,
    editable: (params) => {
      return params.data.id > -1 ? fieldDef.editable : false;
    },
    resizable: fieldDef.resizable,
    cellRenderer: get_cell_renderer(fieldDef),
    width: fieldDef.width,
    hide: fieldDef.hidden,
    options: fieldDef.options,
    cellEditor: get_cell_editor(fieldDef),
    cellEditorParams: {values: fieldDef.options, controller: controller},
    headerTooltip: fieldDef.name,
    tooltipShowDelay: 0,
    headerTooltipShowDelay: 0
  };
  if (fieldDef.pinned) {
    colDef.pinned = "left";
    colDef.lockPinned = true;
  }
  if (fieldDef.type === "dropdown" || fieldDef.type === "text" || fieldDef.type === "bool" || fieldDef.type === "images") {
    colDef.cellEditorPopup = true;
  }
  if (fieldDef.type === "text") {
    colDef.wrapText = true;
    colDef.autoHeight = true;
  }
  if (field === 'order') {
    colDef.sort = 'asc';
    colDef.sortIndex = 0;
  }
  if (field === 'order_nr') {
    colDef.sort = 'asc';
    colDef.sortIndex = 1;
  }
  return colDef;
}


export default {
  name: "ProductTable",
  props: {
    controller: String
  },
  components: {
    AgGridVue, MainMenu
  },
  data() {
    return {
      gridApi: null,
      colApi: null,
    }
  },
  methods: {
    sseJobUpdate: function (message) {
      let updates = JSON.parse(message.data);

      for (const job_id in updates.items) {
        const item = updates.items[job_id]
        if (item.finished) {
          this.refreshImages(item.product_id);
        }
        console.log(item.product_id + "/" + item.file_name + ": " + item.status);

        notify({
          title: 'Bild: ' + item.file_name,
          text: item.status,
          type: item.finished > 0 ? 'success' : (item.ts_error > 0 ? 'error' : 'info'),
          duration:"5000",
        });
      }
    },

    sseJobUpdateEnd: function (event) {
      console.log(event);
      sseClientImgJobs.close();
    },

    refreshImages(productId) {
      fetch(this.controller + "products/" + productId + "/", {credentials: "include"})
          .then((result) => result.json())
          .then((remoteRowData) => {
            const rowNode = this.gridApi.getRowNode(productId);
            const currentRowData = rowNode.data;
            const equals = (a, b) =>
                a.length === b.length &&
                a.every((v, i) => v === b[i]);
            if (equals(currentRowData['images'], remoteRowData.images)) {
              return;
            }
            currentRowData['images'] = remoteRowData.images;
            try {
              const cellDefs = this.gridApi.getEditingCells();
              if (cellDefs.length > 0 && cellDefs[0].column.colId === "images" &&
                  this.rowData.value[cellDefs[0].rowIndex].id === currentRowData['id']) {
                this.gridApi.stopEditing();
              }
            } catch (e) {
              console.log(e);
            }
            rowNode.setData(currentRowData);
          });
    },

    async onCellValueChanged(params) {
      const cellKey = params.column.getColId();
      const cellValue = params.newValue;
      const cellValueOld = params.oldValue;

      if (cellValueOld === cellValue) {
        return;
      }
      let data = {};
      data["id"] = params.data.id;
      data["order_nr"] = params.data.order_nr;
      data[cellKey] = cellValue;

      if (cellKey === "order") {
        this.triggerSort();
      }
      await fetch(this.controller + 'products/', {
        method: 'PUT',
        body: JSON.stringify(data),
        credentials: "include",
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        }
      }).then((response) => response.json())
          .then((data_response) => {
            const rowNode = this.gridApi.getRowNode(data_response.id);
            const currentRowData = rowNode.data;
            if (currentRowData[cellKey] !== data_response[cellKey]) {
              currentRowData[cellKey] = data_response[cellKey];
              rowNode.setData(currentRowData);
              if (!(cellKey === 'images' || cellKey === 'description' || cellKey === 'price')) {
                notify({
                  title: 'Werkstück: ' + data_response.order_nr,
                  text: cellKey + " konnte nicht auf den Wert: <strong>'" + currentRowData[cellKey] + "'</strong> gesetzt werden.<br>Aktueller Wert: <strong>'" + data_response[cellKey] + "'</strong>",
                  type: 'error',
                  duration:"5000",
                });
              }
            } else {
              notify({
                title: 'Werkstück: ' + data.order_nr,
                text: "erfolgreich aktualisiert!",
                type: 'success'
              });
            }
          }).catch(() => {
            notify({
              title: 'Werkstück: ' + data.order_nr,
              text: "Beim Speichern des Werkstücks ist ein Fehler aufgetreten. Ein Hard Refresh könnte nötig sein!",
              type: 'error',
              duration:"5000",
            });
          })
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.colApi = params.columnApi;
    },
  },
  mounted() {
    sseClientImgJobs = new EventSource(this.controller + "sse/image_jobs")
    sseClientImgJobs.addEventListener('message', this.sseJobUpdate)
    sseClientImgJobs.addEventListener("end", this.sseJobUpdateEnd)
  },
  unmounted() {
    sseClientImgJobs.close();
  },
  setup(props) {
    const rowData = reactive({value: [],});
    const columnDefs = reactive({value: []});
    const tableHeight = reactive({value: 8000}); // todo find a better solution for dropdown cutoff problem wtf

    const defaultColDef = {
      sortable: true,
      filter: true,
      editable: false,
    };

    const getRowId = (params) => {
      return params.data.id;
    };

    function adaptTableSize() {
      tableHeight.value = window.innerHeight - 80;
    }

    function triggerSort() {
      this.colApi.applyColumnState({
        state: [{colId: 'order_nr', sort: 'asc'}],
        defaultState: {sort: null},
      });

      this.colApi.applyColumnState({
        state: [
          {colId: 'order', sort: 'asc', sortIndex: 0},
          {colId: 'order_nr', sort: 'asc', sortIndex: 1}
        ],
        defaultState: {sort: null},
      });
    }

    adaptTableSize();
    window.onresize = adaptTableSize;

    onMounted(() => {
      fetch(props.controller + "products/fields/", {credentials: "include"})
          .then((response) => response.json())
          .then((remoteColumnDefData) => {
            columnDefs.value = []
            Object.entries(remoteColumnDefData).forEach(([columnName, colDef]) => {
              columnDefs.value.push(get_columnDef(columnName, colDef, props.controller));
            })
          });

      fetch(props.controller + "products/", {credentials: "include"})
          .then((result) => result.json())
          .then((remoteRowData) => {
            rowData.value = remoteRowData;
          });
    });
    return {
      columnDefs,
      rowData,
      defaultColDef,
      getRowId,
      tableHeight,
      triggerSort,
    };
  },
};
</script>

<style>
.vs__dropdown-toggle {
  /*background: white;*/
  padding: 0;
  height: inherit;
}

.button {
  background: transparent;
  border-radius: 0;
  border: 0.5px gray;
}

.icon-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(80%) contrast(119%);
  height: 27px;
  width: 25px;
}

.icon-green-big {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(80%) contrast(119%);
  height: 96px;
  width: 96px;
}

.icon-red {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(-30deg) brightness(118%) contrast(119%);
  height: 23px;
  width: 23px;
}

.icon-gray {
  filter: invert(48%) sepia(79%) saturate(0%) hue-rotate(-30deg) brightness(118%) contrast(119%);
  height: 23px;
  width: 23px;
}

.icon-orange {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(0deg) brightness(118%) contrast(119%);
  height: 23px;
  width: 23px;
}

.icon-red-small {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(-30deg) brightness(118%) contrast(119%);
  height: 15px;
  width: 15px;
}

.icon-green-small {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(80%) contrast(119%);
  height: 15px;
  width: 15px;
}

.icon-orange-small {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(0deg) brightness(118%) contrast(119%);
  height: 15px;
  width: 15px;
}

.ag-row .ag-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cell-left {
  justify-content: left;
  width: 100%
}

.cell-right {
  justify-content: right;
  width: 100%
}

.cell-wrap {
  line-height: normal !important;
  white-space: normal !important;
  overflow-wrap: break-word !important;
}

.ag-picker-field-wrapper,
.ag-cell-inline-editing {
  border: 0 solid #babfc7;
  /*background: #fff;*/
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0;
  height: 100% !important;
}

.ag-tooltip {
  font-size: large;
}

.ag-popup-editor {
  /*background: white !important;*/
}

.ag-input-field-input {
  /*background: white !important;*/
  height: 100% !important;
  border-radius: 0 !important;
}

.vs__dropdown-toggle {
  width: 100%;
  border: 0 !important;
  border-radius: 0 !important;
  height: 100% !important;
}

.vs__search, .vs__search:focus {
  margin: 0;
  padding: 0;
  min-height: 100px;
  height: 100%;
  border: none !important;
}

.vs__clear .vs__open-indicator {
  display: none !important;
}

.ag-popup-child {
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 0 !important;
}

</style>
