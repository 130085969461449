import {createApp} from 'vue'
import App from './App.vue'
import Notifications from '@kyvg/vue3-notification'
import * as ConfirmDialog from 'vuejs-confirm-dialog'
import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App)
app.use(Notifications)
app.use(ConfirmDialog)
app.use(vue3GoogleLogin, {
    clientId: '739679338791-meiool9ihhv13rnnt472tr028n6tsku5.apps.googleusercontent.com'
})
app.mount('#app')
