<template>
  <div style="text-align: center">
    <div v-if="disabled">
      <img src='/spinner-solid.svg' alt='bild hinzufügen' class='icon-green' style="vertical-align:middle"/><br><br>
      <div class="wait-hint">
        Bitte warten...<br>
      </div>
    </div>
    <div v-else>
      <a style="float:right;" class="logout-button" v-if="loggedIn" :href="routes['logout']">
        <img src='/google.svg' alt='google login icon' class='icon-white-small' style="vertical-align:middle"/>&nbsp; Logout {{user}}
      </a>
      <a style="margin-top:20px;" class="login-button" v-else :href="routes['login']">
        <img src='/google.svg' alt='google logout icon' class='icon-white-small' style="vertical-align:middle"/>&nbsp; Login</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginButton",
  data() {
    return {
      routes: {"login": this.controller + "login", "logout": this.controller + "logout"}
    };
  },
  props: {
    loggedIn: Boolean,
    controller: String,
    disabled: Boolean,
    user:String
  }

}
</script>

<style scoped>
.wait-hint{
  font-size: 18px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.login-button {
  display: inline-block;
  padding: 10px 10px;
  font-size: 15px;
  height:25px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: white;
  background-color: forestgreen;
  border: none;
  border-radius: 2px;
  box-shadow: 3px 3px black;
}

.login-button:hover {
  background-color: darkgreen
}

.login-button:active {
  background-color: darkgreen;
  box-shadow: 1px 1px black;
}

.logout-button {
  display: inline-block;
  padding: 10px 10px;
  font-size: 15px;
  height:25px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: white;
  background-color: indianred;
  border: none;
  border-radius: 2px;
  box-shadow: 3px 3px black;
}

.logout-button:hover {
  background-color: darkred
}

.logout-button:active {
  background-color: darkred;
  box-shadow: 1px 1px black;
}

.icon-white-small {
  filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(100%) contrast(119%);
  height: 20px;
  width: 20px;
}

.icon-green {
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(80%) contrast(119%);
  margin-top: 300px;
  height: 50px;
  width: 50px;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform:rotate(360deg);
  }
}
</style>